<template>
  <div class="pack">
    <!-- 所有弹窗写在这里 -->
    <div class="pop">

    </div>
    <!-- 所有内容写在这里 -->
    <div class="pack_view">
      <div class="viewBtn">
        <div class="retuen"
             @click="skipHome()">
          <i class="el-icon-s-home"></i>
        </div>
      </div>
      <div class="table_view">
        <el-table :data="tableData"
                  stripe
                  max-height="800px">
          <el-table-column prop="id"
                           label="订单ID"
                           width="180">
          </el-table-column>
          <el-table-column label="申请时间"
                           width="180">
            <template #default="scope">
              <span>{{ dateSwitch(scope.row.createtime,'YYYY-MM-DD HH:MIN') }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="jjname"
                           label="申请用户"
                           width="180">
          </el-table-column>
           <el-table-column prop="uid"
                           label="用户ID"
                           width="180">
          </el-table-column>
          <el-table-column prop="jjmobile"
                           label="用户联系方式"
                          >
          </el-table-column>
     
          <el-table-column label="操作"
                           align="right">
            <template #default="scope">
              <el-button @click="skipStartPack(scope.row)"
                         type="primary">打包</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination background
                       layout="prev, pager, next"
                       :total="pageNum"
                       @current-change='currentChange'
                       :page-size='pageSize'>
        </el-pagination>
      </div>

    </div>
  </div>
</template>

<script>
import { postOrder } from '@/request/api'
import { dateSwitch } from '@/assets/common'
export default {
  name: 'Pack',
  data () {
    return {
      // ------页码------
      page: 1,
      pageSize: 10,
      pageNum: 0,
      // ------仓库ID------
      wid: '',
      // ------时间转换函数------
      dateSwitch,
      // ------表格数据（待打包订单）------
      tableData: [

      ]
    }
  },
  methods: {
    //  ------页码切换-------
    currentChange (e) {
      this.page = e
      this.getOrder()
    },
    // ------获取待打包订单-------
    async getOrder () {
      let res = await postOrder({
        op: 'list',
        pd: 'getlist',
        ckid: this.wid,
        page: this.page,
        pageSize: this.pageSize,
        status: 33
      })
      if (res.errno == 0) {
        this.tableData = res.orderlist
        this.pageNum = parseInt(res.totalNum, 10)
      } else {
        this.$message.error(res.message)
      }
    },
    //   ------返回首页------
    skipHome () {
      this.$router.push({
        name: 'Index',
      })
    },
    //   ------跳转开始打包------
    skipStartPack (row) {
      this.$router.push({
        name: 'start_pack',
        query: {
          id: row.id
        }
      })
    }
  },
  async created () {
    let wid = JSON.parse(localStorage.getItem('ty_warehouseData'))
    let cklist = JSON.parse(localStorage.getItem('cklist'))
    //  --------- 
    if (cklist) {
      if (wid) {
        this.wid = wid.id
      } else {
        this.wid = cklist.cklist[0].id
      }
      this.getOrder()
    } else {
      await this.$store.dispatch('cklist')
      localStorage.setItem('cklist', this.$store.state.warehouse)
      if (wid) {
        this.wid = wid.id
      } else {
        this.wid = cklist.cklist[0].id
      }
      this.getOrder()
    }
  }
}
</script>

<style lang='scss'>
.pack {
  background-color: #409eff;
  height: 100%;
  .pack_view {
    height: 95%;
    padding: 0 40px 40px 40px;
    box-sizing: border-box;
    .viewBtn {
      padding: 10px 0;
      .retuen:hover {
        box-shadow: 0 2px 12px 0 rgba(255, 255, 255, 0.5);
        // width: 30px;
        // height: 30px;
      }

      .retuen {
        cursor: pointer;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        font-weight: 700;
        color: #4c90fd;
        border-radius: 50%;
        background-color: #fff;
        box-shadow: 0 2px 12px 0 rgba(255, 255, 255, 0.1);
        transition: all 0.3s;
      }
    }
    .table_view {
      width: 100%;
      height: 100%;
      background-color: #fff;
      padding: 10px 20px;
      box-sizing: border-box;
      border-radius: 20px;
      box-shadow: 0 2px 12px 0 rgba(255, 255, 255, 0.1);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      .el-pagination {
        padding-bottom: 10px;
      }
    }
    .el-table {
      width: 100%;
      //   height: 100%;
      .tableoperation {
        // width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-right: 200px;
      }
      table {
        // width: auto !important;
      }
    }
  }
}
</style>


